<template>
  <integration-form
    ref="formRef"
    :set="setUsps"
    :get="getUsps"
    :fields="fields"
    title="USPS"
  />
</template>

<script>
import { mapActions } from 'vuex'
import config from './config'
import mixin from '../mixin'

export default {
  name: 'USPS',
  mixins: [mixin],
  methods: {
    ...mapActions('settingsIntegrations', ['getUsps', 'setUsps']),
  },
  setup() {
    const { fields } = config()
    return {
      fields,
    }
  },
}
</script>

<style scoped>

</style>
