import { TEXT_INPUT } from '@/views/components/DynamicForm/constants'

export default function config() {
  const fields = {
    usps_key: {
      type: TEXT_INPUT,
      label: 'USPS Key',
      rules: 'required',
      placeholder: 'Type...',
    },
    usps_api_key: {
      type: TEXT_INPUT,
      label: 'USPS API Key',
      rules: 'required',
      placeholder: 'Type...',
    },
  }
  return {
    fields,
  }
}
